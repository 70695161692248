<template>
  <div
    style="
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1312;
      transition: transform 0.2s, background 0.3s;
    "
    :style="`transform: translateY(${isActive ? top : top + 1000}px); left: ${
      isOpenSidebar ? 220 : 0
    }px`"
    class="sticky-bottom-header q-pa-md row items-center justify-center"
  >
    <q-carousel
      :model-value="slide"
      transition-prev="slide-right"
      transition-next="slide-left"
      animated
      style="max-width: 600px"
      height="auto"
    >
      <q-carousel-slide name="default" class="q-pa-none">
        <div
          class="
            bg-dark bg-animated
            text-white
            q-py-sm q-px-md
            rounded
            shadow
            row
            items-center
            col
          "
        >
          <div
            class="sticky-bottom-header__content text-subtitle2 col q-pr-lg"
            v-if="saved"
          >
            {{ $t("Changes saved!") }}
          </div>
          <div
            class="sticky-bottom-header__content text-subtitle2 col q-pr-lg"
            v-else-if="!saved"
          >
            {{ $t("Caution! You didn't save the changes!") }}
          </div>

          <div class="sticky-bottom-header__actions">
            <q-btn
              color="dark"
              class="q-mr-sm bg-white"
              size="md"
              :label="$t('Discard')"
              no-caps
              outline
              unelevated
              @click="handleYes"
            />

            <q-btn
              color="light-blue-9"
              text-color="white"
              :label="saveLabel || $t('Save')"
              size="md"
              no-caps
              unelevated
              :disable="disabled"
              @click="handleWarning"
            />
          </div>
        </div>
      </q-carousel-slide>

      <q-carousel-slide
        name="loading"
        class="
          bg-dark bg-animated
          text-white
          q-py-sm q-px-md
          rounded
          shadow
          row
          items-center
        "
      >
        <svg
          focusable="false"
          width="1em"
          height="1em"
          viewBox="25 25 50 50"
          class="q-spinner q-spinner-mat q-notification__spinner q-mr-md"
        >
          <circle
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="currentColor"
            stroke-width="5"
            stroke-miterlimit="10"
            class="path"
          />
        </svg>

        <div class="q-notification__message col">
          {{ $t("Please wait...") }}
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Quasar
import { debounce } from 'quasar'

function getDefaultPosition () {
  const height = window.innerWidth > 700
    ? 80
    : 150

  return window.innerHeight - height 
}

export default {
  name: 'StickyBottomHeader',
  emits: ['save', 'back'],
  props: {
    saveLabel: {
      type: String,
      default () {
        return ''
      }
    },
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    isActive: {
      type: Boolean,
      default () {
        return true
      }
    },
    saved: {
      type: Boolean,
      default () {
        return false
      }
    },
  },
  data () {
    return {
      slide: 'default',
      top: getDefaultPosition()
    }
  },
  computed: {
    ...mapGetters([
      'isOpenSidebar'
    ])
  },
  watch: {
    isLoading (newVal) {
      this.setIsLoadingBottomHeader(newVal)
      this.slide = newVal
        ? 'loading'
        : 'default'
    }
  },
  mounted () {
    this.handleResize = this.handleResize.bind(this)
    this.handleResize = debounce(this.handleResize, 500)
    window.addEventListener('resize', this.handleResize)
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations([
      'setIsLoadingBottomHeader'
    ]),
    handleNo () {
      this.slide = 'default'
    },
    handleYes (e) {
      this.slide = 'default'
      this.$vibrate()
      this.$emit('back', e)
    },
    handleWarning (e) {
      this.$emit('save', e)
    },
    handleResize () {
      this.top = getDefaultPosition()
    }
  }
}
</script>
